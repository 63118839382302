@media (min-width: 700px) {
	#about[open] {
		height: 455px;
	}
}

h1, h2, h3, h4, nav, summary, button#start {
	font-weight: 900;
}

nav {
	height: 88px;
}

@media (max-width: 384px) {
	nav {
		height: 132px;
	}
}

nav>ul {
	flex-wrap: wrap;
}

nav a {
	white-space: nowrap;
}